import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Footer from "./footer";
import Promotion from "./promotion";
import ProductLogoShowcase from "./product-logo-showcase";
import "./layout.css";
import "../styles/index.scss";
import "../styles/output.css";
import "../styles/custom.scss";
import "../styles/global.css";

import { adRefCity } from "../helpers/adRefTracking";
import Navbar from "./Navbar";

const Layout = ({ children, pageProps, footerLogoShowcase, isHomePage, customSchema, headerTransparent, headerShadow }) => {
  if (typeof window !== "undefined" && window.localStorage && !window.localStorage.getItem("landingURL")) {
    window.localStorage.setItem("landingURL", pageProps.location.href);
    window.localStorage.setItem("landingDate", new Date());
  }
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              description
              companyInfo {
                phone
                email
                addressLine1
                addressLine2
                city
                state
                zip
                license
              }
            }
          }
          allContentfulNavigation(filter: { name: { eq: "Main Navigation" } }) {
            edges {
              node {
                id
                name
                menuItems {
                  id
                  name
                  title
                  url
                  openInNewWindow
                  submenu {
                    id
                    name
                    menuItems {
                      id
                      name
                      title
                      url
                      openInNewWindow
                    }
                  }
                }
                campaign
              }
            }
          }
        }
      `}
      render={(data) => (
        <>
          <Navbar
            siteTitle={data.site.siteMetadata.title}
            siteDescription={adRefCity(data.site.siteMetadata.description)}
            companyInfo={data.site.siteMetadata.companyInfo}
            pageProps={pageProps}
            headerTransparent={headerTransparent}
            shadow={headerShadow}
          />

          {/* <Header
            siteTitle={data.site.siteMetadata.title}
            siteDescription={adRefCity(data.site.siteMetadata.description)}
            companyInfo={data.site.siteMetadata.companyInfo}
            mainNavigation={
              data.allContentfulNavigation.edges.filter(
                ({ node: { campaign } }) =>
                  campaign.length > 0 ? campaign[0] === 'default' : false
              )[0].node.menuItems
            }
            menus={data.allContentfulNavigation.edges}
            pageProps={pageProps}
          /> */}

          <div className="layout-container">{children}</div>
          <ProductLogoShowcase footerLogoShowcase={footerLogoShowcase} />
          <Footer isHomePage={isHomePage} />
          <Promotion />
          {!customSchema && (
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: `
              {
                "@id": "https://perfectaw.com/#HomeAndConstructionBusiness",
                "@context": "https://www.schema.org",
                "@type": "HomeAndConstructionBusiness",
                "name": "Perfect Air & Water Services",
                "url": "https://perfectaw.com/",
                "logo": "https://images.ctfassets.net/fx9youd8flii/5ET9Kj21NahJ9Jk5a8tCFP/4c1a9abe83b8750e8283098f200708a1/paws-thumb.jpg",
                "image": "https://images.ctfassets.net/fx9youd8flii/5ET9Kj21NahJ9Jk5a8tCFP/4c1a9abe83b8750e8283098f200708a1/paws-thumb.jpg",
                "description": "Perfect Air & Water Services is a locally owned and operated full service plumbing and HVAC contractor in New Jersey, NJ specializing in water heaters, plumbing, boiler replacement, furnace installs, air quality testing to your residential, commercial and industrial projects.
                ",
                "address": {
                    "@type": "PostalAddress", 
                    "streetAddress": "170 Dutch Lane Rd",
                    "addressLocality": "Freehold",
                    "addressRegion": "NJ",
                    "postalCode": "07728",
                    "addressCountry": "US"
                },
  
                "openingHours": "Mo, Tu, We, Th, Fr 08:00-18:00",
                "contactPoint": {
                    "@type": "ContactPoint",
                    "contactType": "sales",
                    "telephone": "+1 888-578-2776"
                }
            }`,
              }}
            />
          )}
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

//classes for tailwind to include when doing purge
// pr-2
